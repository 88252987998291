import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { FaEllipsisV } from "react-icons/fa";
import url from "../../API";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomButton from "../CustomButton/Index";
import ImageWithPopup from "../ImagewithPop/Index";
import { Box } from "@mui/material";
import classNames from "classnames";
import { FaTimes, FaUserFriends } from "react-icons/fa";
import countryStyles from "../../styles/pages/CountryPage/index.style";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import JoditEditor from "jodit-react";
const CustomMenuButton = ({ menuinfo, onStatusUpdate, table }) => {
  const classes = countryStyles();
  const token = localStorage.getItem("tkn");
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
  const [paymentDetailsOpen, setPaymentDetailsOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [sendMailOpen, setSendMailOpen] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  const [mail, setMail] = useState({
    email_subject: "",
    email_body: EditorState.createEmpty(),
    logo: [],
  });
  let paymentData = {};
  let couponData = {};

  if (
    menuinfo.payment_details.length !== 0 &&
    menuinfo.payment_details[0].payment_data !== "None" &&
    menuinfo.payment_details[0].payment_data !== ""
  ) {
    try {
      let rawPaymentData = menuinfo.payment_details[0].payment_data.trim(); // Clean the string
      // console.log("Raw payment_data:", rawPaymentData); // Debugging log
      paymentData = JSON.parse(rawPaymentData); // Attempt to parse JSON
      // console.log(paymentData, "Parsed paymentData");

      couponData = {
        coupon_code: menuinfo.payment_details[0].coupon_code || "N/A",
        coupon_id: menuinfo.payment_details[0].coupon_id || "N/A",
        off_type:
          menuinfo.payment_details[0].off_type === 1
            ? "Percentage"
            : menuinfo.payment_details[0].off_type === 2
            ? "Flat off"
            : "N/A",
        off_value: menuinfo.payment_details[0].off_value
          ? (menuinfo.payment_details[0].off_value / 100).toFixed(2)
          : "N/A",
      };

      // Map fields based on payment type
      if (menuinfo.payment_details[0].payment_type === "paypal") {
        paymentData = {
          id: paymentData.id || "N/A",
          amount_total:
            parseFloat(paymentData.purchase_units[0].amount.value) || "N/A",
          currency: paymentData.purchase_units[0].amount.currency_code || "N/A",
          payment_status: paymentData.status || "N/A",
          customer_details: {
            name: paymentData.payer.name
              ? `${paymentData.payer.name.given_name} ${paymentData.payer.name.surname}`
              : "N/A",
            email: paymentData.payer.email_address || "N/A",
          },
        };
      } else if (menuinfo.payment_details[0].payment_type === "stripe") {
        paymentData = {
          id: paymentData.id || "N/A",
          amount_total: paymentData.amount_received || "N/A",
          currency: paymentData.currency || "N/A",
          payment_status: paymentData.status || "N/A",
          customer_details: {
            name:
              (paymentData.metadata && paymentData.metadata.customer_name) ||
              "N/A",
            email: paymentData.receipt_email || "N/A",
          },
        };
      }
    } catch (error) {
      console.error("Error parsing payment data:", error);
      paymentData = {};
      couponData = {};
    }
  }

  const [state, setState] = useState({
    transaction_id:
      menuinfo.payment_details.length > 0 &&
      menuinfo.payment_details[0].transaction_id
        ? menuinfo.payment_details[0].transaction_id
        : "",
    payment_status:
      menuinfo.payment_details.length > 0 &&
      menuinfo.payment_details[0].transaction_status
        ? menuinfo.payment_details[0].transaction_status
        : "",
    payment_type:
      menuinfo.payment_details.length > 0 &&
      menuinfo.payment_details[0].payment_type
        ? menuinfo.payment_details[0].payment_type
        : "",
  });

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log(menuinfo);
  const handleView = () => {
    localStorage.setItem("applicantInfo", JSON.stringify(menuinfo));
    navigate("/application_details");
    handleClose();
  };

  const handleDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: menuinfo.id };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "applicationform/deleteapplicationform",
        requestOptions
      );
      const actualData = await res.json();
      // console.log(actualData);
      await table(); // Call the fetchData function from the parent component
    } catch (err) {
      console.log(err);
    }
    handleClose();
    setConfirmDeleteOpen(false); // Close the confirmation dialog after deletion
  };
  const handleSaveVisaCategory = () => {
    const contentState = mail.email_body.getCurrentContent();
    const html = stateToHTML(contentState);
    axios
      .post(
        url + "email/sendemailtouser/",
        {
          user_id: menuinfo.user_id,
          body: html,
          subject: mail.email_subject,
          files: mail.logo,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          return true;
        } else {
          toast.error(r.data.message, {
            position: "top-center",
          });
        }

        // table2();
        // localStorage.removeItem("logo");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleStatusUpdate = (val) => () => {
    onStatusUpdate(menuinfo, val);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatePayment = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        application_form_id: menuinfo.id,
        transaction_id: state.transaction_id,
        transaction_status: state.payment_status,
        paymentData: menuinfo.payment_details[0].payment_data,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "paymentdetails/updatepaymentdetail",
        requestOptions
      );
      const actualData = await res.json();
      // console.log(actualData);
      await table(); // Call the fetchData function from the parent component
      setUpdateStatusOpen(false);
      setPaymentDetailsOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const createPayment = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        application_form_id: menuinfo.id,
        transaction_id: state.transaction_id,
        transaction_status: state.payment_status,
        payment_type: state.payment_type,
        payment_data: {},
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "paymentdetails/createpaymentdetail",
        requestOptions
      );
      const actualData = await res.json();
      // console.log(actualData);
      await table(); // Call the fetchData function from the parent component
      setUpdateStatusOpen(false);
      setPaymentDetailsOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateStatusClick = () => {
    setUpdateStatusOpen(true);
    handleClose();
  };

  const handlePaymentDetailsClick = () => {
    setPaymentDetailsOpen(true);
    handleClose();
  };

  const handleDialogClose = () => {
    setUpdateStatusOpen(false);
    setPaymentDetailsOpen(false);
    setSendMailOpen(false);
  };

  const handleConfirmDeleteOpen = () => {
    setConfirmDeleteOpen(true);
    handleClose();
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleSendMailClick = () => {
    setSendMailOpen(true);
    handleClose();
  };

  const handleSendMail = async () => {
    // Implement the logic to send the mail here.
   
    const val = handleSaveVisaCategory();
    // After sending the mail, close the dialog
    if (val) {
      setSendMailOpen(false);
    }
  };
  const removelogoPhotos = (index, chk) => {
    const newImages = [...mail.logo];
    newImages.splice(index, 1);

    setMail({ ...mail, logo: newImages });
  };
  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View/Edit</MenuItem>
        <MenuItem onClick={handleConfirmDeleteOpen}>Remove</MenuItem>
        <MenuItem onClick={handleUpdateStatusClick}>
          Update Payment Status
        </MenuItem>
        <MenuItem onClick={handlePaymentDetailsClick}>
          Payment Details Information
        </MenuItem>
        <MenuItem onClick={handleSendMailClick}>Send Mail</MenuItem>
      </Menu>

      <Dialog open={updateStatusOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Payment Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new payment status:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="paymentStatus"
            label="Payment Status"
            type="text"
            fullWidth
            value={state.payment_status} // Set the value of the text field
            onChange={(e) =>
              setState({
                ...state,
                payment_status: e.target.value,
              })
            }
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="transactionId"
            label="Transaction Id"
            type="text"
            fullWidth
            value={state.transaction_id} // Set the value of the text field
            onChange={(e) => {
              const input = e.target.value;
              const trimmedInput = input.slice(0, 17);
              setState({
                ...state,
                transaction_id: trimmedInput,
              });
            }}
            variant="standard"
          />
          {menuinfo.payment_details.length === 0 && (
            <TextField
              autoFocus
              margin="dense"
              id="paymentType"
              label="Payment Type"
              type="text"
              fullWidth
              value={state.payment_type} // Set the value of the text field
              onChange={(e) =>
                setState({
                  ...state,
                  payment_type: e.target.value,
                })
              }
              variant="standard"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={
              menuinfo.payment_details.length !== 0
                ? updatePayment
                : createPayment
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={paymentDetailsOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Payment Details
          </Typography>
        </DialogTitle>

        <DialogContent>
          {/* Payment ID */}
          <Box mb={2}>
            <Typography
              variant="subtitle1"
              sx={{ wordWrap: "break-word", fontWeight: "bold" }}
            >
              Payment ID:
            </Typography>
            <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
              {state.transaction_id || "N/A"}
            </Typography>
          </Box>

          {/* Payment Information */}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Amount Total
              </Typography>
              <Typography variant="body2">
                {paymentData.amount_total
                  ? (paymentData.amount_total / 100).toFixed(2)
                  : "N/A"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Currency
              </Typography>
              <Typography variant="body2">
                {paymentData.currency
                  ? paymentData.currency.toUpperCase()
                  : "N/A"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Payment Status
              </Typography>
              <Typography variant="body2">
                {paymentData.payment_status || "N/A"}
              </Typography>
            </Grid>

            {/* <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Customer Name
              </Typography>
              <Typography variant="body2">
                {paymentData?.customer_details?.name || "N/A"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Customer Email
              </Typography>
              <Typography variant="body2">
                {paymentData?.customer_details?.email || "N/A"}
              </Typography>
            </Grid> */}

            {/* Coupon Information */}
            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Coupon Code
              </Typography>
              <Typography variant="body2">{couponData.coupon_code}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Coupon ID
              </Typography>
              <Typography variant="body2">{couponData.coupon_id}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Offer Type
              </Typography>
              <Typography variant="body2">{couponData.off_type}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Offer Value
              </Typography>
              <Typography variant="body2">{couponData.off_value}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={sendMailOpen} onClose={handleDialogClose}>
        <DialogTitle>Send Mail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email subject and body:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="emailSubject"
            label="Email Subject"
            type="text"
            fullWidth
            value={mail.email_subject}
            onChange={(e) =>
              setMail({
                ...mail,
                email_subject: e.target.value,
              })
            }
            variant="standard"
          />
          <div style={{ marginTop: "16px" }}>
            <p>Email body</p>
            {/* <Editor
              editorState={mail.email_body}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(editorState) =>
                setMail({
                  ...mail,
                  email_body: editorState,
                })
              }
            /> */}
            <JoditEditor
              ref={editor}
              value={state.email_body}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) =>
                setState({
                  ...state,
                  email_body: newContent,
                })
              } // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
              config={config}
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <p>Upload Files</p>
            <Box
              display="flex"
              alignItems="center"
              style={{
                padding: "4px",
                borderColor: "rgba(148,158,171,0.32)",
                boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                width: "100%",
                borderRadius: "12px",
              }}
              className={classNames(classes.root)}
            >
              <Button
                variant="contained"
                component="label"
                style={{
                  marginRight: "1rem",
                  backgroundColor: "#f0f0f0",
                  color: "#27282C",
                  fontWeight: "500",
                  boxShadow: "none",
                }}
              >
                Choose File
                <input
                  type="file"
                  hidden
                  multiple
                  fullWidth
                  label="Visa Photos"
                  onChange={async (e) => {
                    const files = Array.from(e.target.files);
                    if (files.length > 0) {
                      setFileName(`${files.length} file(s) chosen`);
                    } else {
                      setFileName("No file chosen");
                    }

                    const newPhotos = [];

                    for (let i = 0; i < files.length; i++) {
                      const file = files[i];
                      const imageRef = ref(storage, `/images+${Math.random()}`);

                      try {
                        await uploadBytes(imageRef, file);
                        const url = await getDownloadURL(imageRef);
                        newPhotos.push(url);
                      } catch (error) {
                        console.error("Error uploading file:", error);
                      }
                    }

                    setMail((prevMail) => ({
                      ...prevMail,
                      logo: [...prevMail.logo, ...newPhotos],
                    }));
                  }}
                />
              </Button>
              <span style={{ color: "#27282C", fontWeight: "500" }}>
                {fileName}
              </span>
            </Box>
          </div>
          <Grid item xs={12} style={{ display: "flex", gap: "20px" }}>
            {mail.logo != null
              ? mail.logo.map((el, idx) => (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* <img src={el} style={{ height: "50px" }} /> */}
                      <ImageWithPopup src={el} alt="Example Image" />

                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={() => removelogoPhotos(idx, "new")}
                        icon={<FaTimes />}
                      />
                    </div>
                  </>
                ))
              : console.log(mail.logo)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSendMail}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomMenuButton;
