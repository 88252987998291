import { Divider, Paper } from "@mui/material";
import React from "react";
import CustomButton from "../../components/CustomButton/Index";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import { Download } from "@mui/icons-material";
import { useLocation } from "react-router";

export default function CareerApplicantList() {
  const classes = offerStyles();
  const token = localStorage.getItem("tkn");
  const location = useLocation();
  const [jobList, setjobList] = React.useState([]);

  const { slug } = location.state || {};

  // console.log(location.state);
  // console.log(slug);

  function table() {
    axios
      .post(
        url + "job/userdetailsbyjobid",
        {
          job_slug: slug
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        // Handle the response
        setjobList(response.data.jobs);
        // console.log(response.data.jobs);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();
  }, []);

  const handleDownload = (name, resumeURL) => {
    fetch(resumeURL)
      .then(response => response.blob()) // Fetch the PDF file as a Blob
      .then(blob => {
        // Create a link element to simulate a download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob); // Create an object URL for the Blob
        link.download = `resume_${name}`; // Specify the file name

        document.body.appendChild(link); // Append link element to DOM
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove link after clicking
      })
      .catch(error => {
        console.error("Error fetching the PDF:", error);
      });
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px"
            }}
          >
            {" "}<p style={{ marginTop: "2px", fontSize: "22px" }}>Careers</p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "Applicant Name",
                "Phone No.",
                "Email",
                "Resume"
              ]}
              rows={[
                ...jobList?.map((vs, idx) => [
                  idx + 1,
                  vs.name,
                  vs.phone_number,
                  vs.email,

                  <span
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center"
                    }}
                    onClick={() => handleDownload(vs.name, vs.cv)}
                  >
                    <CustomButton
                      variant="contained"
                      color="warning"
                      icon={
                        <Download style={{ color: "white" }} fontSize="small" />
                      }
                    />
                  </span>
                ])
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
